
/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: auto;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
    width: 11%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 20px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #0159fc;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 70%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbarbutton {
  font-weight: 700;
  color:#fff;
  border: 1px solid rgb(0, 68, 255);
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
  border-radius: 17px;
  text-decoration: none;
}
.vvd,.navbarbutton {
  color: #ffd700;
}
.navbarbutton span {
  z-index: 1;
}
.navbarbutton::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: rgb(0, 140, 255);
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
  border-radius: 17px;
}
.vvd:hover {
  color: #ffd700;
}
.navbarbutton:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
  border-radius: 17px;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 34px;
  height: 27px;
  background-image: none;
  position: relative;
  border-bottom: 4px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 27px;
	position: absolute;
	height: 3px;
	background-color: #fff;
	top: -1px;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 10px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./assets/img/banner-bg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(37, 65, 156, 0.5) -5.91%, rgba(34, 127, 233, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
  border-radius: 17px;
}
.banner h1 {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #ffd700;
  font-style: TaHoma;
  font-size: 30px;
  letter-spacing: 0.8px;
  line-height: 1em;
  width: 100%;
}
.video-trigger {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-bottom: 10px; 
  margin-top: 20px;/* Adds space between video trigger and button */
}

.thumbnail-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50px;
}

.video-text {
  color: #ffd700;
  font-style: TaHoma;
  letter-spacing: 0.8px;
  font-size: 25px;
  line-height: 1em; /* Assuming your text is white like the rest of your banner */
  text-decoration: underline;
  flex-shrink: 0;
  width: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
}

.close-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  transition: all 0.3s ease;
}

.close-button:hover {
  background: #ffffff;
  transform: scale(1.1);
}

.modal-content iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.banner button {
  color: rgb(0, 255, 221);
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  text-decoration: none;

}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Services Css ************/
.services {
  padding: 0 0 50px 0;
  position: relative;
}
.services-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.services h2 {
	font-size: 45px;
	font-weight: 700;
}
.services p {
    color: #00ccff;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
}
.services h3 {
  color: #ffd700;
  font-size: 20px;
}
.services-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.services-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}
.background-image-right {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************ VAI Css ************/
.vai {
  padding: 50px 0;
  position: relative;
  background-color: rgba(0, 0, 0, 0.658);
  text-align: center;
}
.vai h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.vai p {
  color: #ffd700;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.vai .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.vai .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.vai .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.vai .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #002d57 -5.91%, #b700ff 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.vai .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.vai .nav.nav-pills .nav-link.active {
    border: 1px solid rgb(0, 47, 255, 0.5);
}
.nav-link#vai-tabs-tab-first {
  border: 1px solid rgba(0, 47, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#vai-tabs-tab-second {
  border-top: 1px solid rgba(0, 47, 255, 0.5);
  border-bottom: 1px solid rgba(0, 47, 255, 0.5);
}
.nav-link#vai-tabs-tab-third {
  border: 1px solid rgba(0, 47, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  opacity: 100;
  position: absolute;
  width: 100%;
  height: 0;
  
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 0;
  transition: 10s ease-in-out;
}
.proj-txtx h4 {
  font-size: 27px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  color: #ffd700;
  background: linear-gradient(90.21deg, #000000 -5.91%, #000000 111.58%);
}
.proj-txtx span {
  font-style: TaHoma;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
  background: linear-gradient(90.21deg, #000000 -5.91%, #000000 111.58%);
}
.proj-imgbx2 {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx2::before {
  content: "";
  background: linear-gradient(90.21deg, #38387a -5.91%, #00cd89b6 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx2:hover::before {
  height: 100%;
}
.proj-txtx2 {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx2:hover .proj-txtx2 {
  top: 50%;
  opacity: 1;
}
.proj-txtx2 h4 {
  font-size: 37px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  color: #fff;
}
.proj-txtx2 h5 {
  font-size: 27px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  color: #cdffc7;
  padding-top: 30px;
}
.proj-txtx2 span {
  font-size: 67px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  color: #4affb4;
}
.proj-imgbx3 {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx3::before {
  content: "";
  background: linear-gradient(90.21deg, #38387a -5.91%, #0059cd 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx3:hover::before {
  height: 100%;
}
.proj-txtx3 {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx3:hover .proj-txtx3 {
  top: 50%;
  opacity: 1;
}
.proj-txtx3 h4 {
  font-size: 37px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  color: #fff;
}
.proj-txtx3 h5 {
  font-size: 27px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  color: #55c9ff;
  padding-top: 30px;
}
.proj-txtx3 span {
  font-style: BOLD;
  font-weight: 400;
  font-size: 50px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Contact Css ************/
.contact {
  background: linear-gradient(90.21deg, #040f70b4 -5.91%, #120172b7 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 55px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.button {
  font-weight: 700;
  color: #000;
  background-color: rgb(255, 215, 0);
  padding: 14px 48px;
  font-size:20px;
  margin-top: 25px;
  border-radius: 10px;
  position: relative;
  transition: 0.3s ease-in-out;
  text-decoration: none;
}
.button:hover {
  color: rgb(0, 255, 255);
}
.button span {
  z-index: 1;
  position: relative;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-image: url('./assets/img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.newsletter-bx {
  background: #FFFFFF;
  border-radius: 35px;
  color: #121212;
  padding: 35px 55px;
  margin-bottom: 150px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 1.2em;
  font-size: 54px;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #0734f8 -5.91%, #062c7e 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #0e0720 -5.91%, #06127e 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.footer img {
  width: 12%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

/* **********************Desktop container utility End******************** */

@media screen and (min-width:1199px) and (max-width: 1200px) {

}

@media screen and (min-width:1096px) and (max-width:1199px) {
  .banner h1 {
    min-height: 60px;
    font-size: 35px;
    width: 200%;
	}
  .banner p {
    color: #ffd700;
    font-style: TaHoma;
    font-size: 33px;
    letter-spacing: 0.8px;
    line-height: 1em;
    width: 130%;
  }
  /* .video-trigger {
    display: flex;
    flex-direction: row;
  }
  .thumbnail-image {
    right: 911415px;
    top: 200px;
  } */
  .services p {
    color: #00ccff;
    font-size: 30px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
}
.services h3 {
  color: #ffd700;
  font-size: 30px;
}
  .row{
    display: flex;
    flex-direction: column;
    display: grid;
  }
  .proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 80.6vw;
  }
  .proj-imgbx2 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 80.6vw;
  }
  .proj-imgbx3 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 80.6vw;
  }
  .proj-txtx h4 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
  }
  .proj-txtx span {
    font-style: TaHoma;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 0.8px;
  }
  .new-email-bx {
    width: 480px;
    right: 40px;
    position: relative; top: 25px;
  }
  .new-email-bx button {
    padding: 20px 30px;
  }
  .newsletter-bx h3 {
    font-weight: 700;
    font-size: 38px;
    line-height: 27px;
    position: relative; right: 70px;
    margin: -5px 30px;
    text-align: justify;
  }
  .banner img{
    margin-left: 115%;
    margin-top: -200px;
  }
  .footer .social-icon{
    position: relative; top: 70px;
    position: relative; left: auto;
    width: 70%;
    right: 34%;
  }
  .cpytex {
    position: relative; top: 55px;
    position: relative; left: auto;
    width: 200%;
    right: 40%;
  }
  .contact img {
    width: 150%;
  }
  .contact h2 {
    font-size: 55px;
    margin-bottom: 30px;
    width: 150%;
  }
}

@media screen and (min-width:992px) and (max-width:1095px) {
  .banner h1 {
    min-height: 60px;
    font-size: 35px;
    width: 200%;
	}
  .banner p {
    color: #ffd700;
    font-style: TaHoma;
    font-size: 33px;
    letter-spacing: 0.8px;
    line-height: 1em;
    width: 130%;
  }
  .services p {
    color: #00ccff;
    font-size: 30px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
}
.services h3 {
  color: #ffd700;
  font-size: 30px;
}
  .row{
    display: flex;
    flex-direction: column;
    display: grid;
  }
  .proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 85.6vw;
  }
  .proj-imgbx2 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 85.6vw;
  }
  .proj-imgbx3 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 85.6vw;
  }
  .proj-txtx h4 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
  }
  .proj-txtx span {
    font-style: TaHoma;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 0.8px;
  }
  .new-email-bx {
    width: 480px;
    right: 40px;
    position: relative; top: 25px;
  }
  .new-email-bx button {
    padding: 20px 30px;
  }
  .newsletter-bx h3 {
    font-weight: 700;
    font-size: 38px;
    line-height: 27px;
    position: relative; right: 70px;
    margin: -5px 30px;
    text-align: justify;
  }
  .banner img{
    margin-left: 115%;
    margin-top: -200px;
  }
  .footer .social-icon{
    position: relative; top: 70px;
    position: relative; left: auto;
    width: 70%;
    right: 34%;
  }
  .cpytex {
    position: relative; top: 55px;
    position: relative; left: auto;
    width: 200%;
    right: 40%;
  }
  .contact img {
    width: 150%;
  }
  .contact h2 {
    font-size: 55px;
    margin-bottom: 30px;
    width: 150%;
  }
}

@media screen and (min-width:901px) and (max-width:991px) {
  .banner h1 {
    min-height: 60px;
    font-size: 35px;
    width: 200%;
	}
  .banner p {
    color: #ffd700;
    font-style: TaHoma;
    font-size: 33px;
    letter-spacing: 0.8px;
    line-height: 1em;
    width: 130%;
  }
  .services p {
    color: #00ccff;
    font-size: 30px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
}
.services h3 {
  color: #ffd700;
  font-size: 30px;
}
  .row{
    display: flex;
    flex-direction: column;
    display: grid;
  }
  .proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 73.6vw;
  }
  .proj-imgbx2 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 73.6vw;
  }
  .proj-imgbx3 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 73.6vw;
  }
  .proj-txtx h4 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
  }
  .proj-txtx span {
    font-style: TaHoma;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 0.8px;
  }
  .new-email-bx {
    width: 480px;
    right: 40px;
    position: relative; top: 25px;
  }
  .new-email-bx button {
    padding: 20px 30px;
  }
  .newsletter-bx h3 {
    font-weight: 700;
    font-size: 38px;
    width: 200%;
    line-height: 27px;
    position: relative; right: 70px;
    margin: -5px 30px;
    text-align: justify;
  }
  .ms-auto{
    display: flex;
    flex-direction: row !important;
    position: relative; left: 50px;
    width: 100%;
  }
  .banner img{
    margin-left: 130%;
    margin-top: -200px;
  }
  .footer .social-icon{
    position: relative; top: 70px;
    position: relative; left: auto;
    width: 70%;
    right: 28%;
  }
  .cpytex {
    position: relative; top: 55px;
    position: relative; left: auto;
    width: 200%;
    right: 40%;
  }
  .contact img {
    width: 150%;
  }
  .contact h2 {
    font-size: 55px;
    margin-bottom: 30px;
    width: 150%;
  }
}

@media screen and (min-width:801px) and (max-width:900px) {
  .banner h1 {
    min-height: 60px;
    font-size: 35px;
    width: 200%;
	}
  .banner p {
    color: #ffd700;
    font-style: TaHoma;
    font-size: 33px;
    letter-spacing: 0.8px;
    line-height: 1em;
    width: 130%;
  }
  .services p {
    color: #00ccff;
    font-size: 30px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
}
.services h3 {
  color: #ffd700;
  font-size: 30px;
}
  .row{
    display: flex;
    flex-direction: column;
    display: grid;
  }
  .proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 80.6vw;
  }
  .proj-imgbx2 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 80.6vw;
  }
  .proj-imgbx3 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 80.6vw;
  }
  .proj-txtx h4 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
  }
  .proj-txtx span {
    font-style: TaHoma;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 0.8px;
  }
  .new-email-bx {
    width: 480px;
    right: 40px;
    position: relative; top: 25px;
  }
  .new-email-bx button {
    padding: 20px 30px;
  }
  .newsletter-bx h3 {
    font-weight: 700;
    font-size: 38px;
    line-height: 27px;
    width: 200%;
    position: relative; right: 70px;
    margin: -5px 30px;
    text-align: justify;
  }
  .ms-auto{
    display: flex;
    flex-direction: row !important;
    position: relative; left: 50px;
    width: 100%;
  }
  .banner img{
    margin-left: 120%;
    margin-top: -200px;
  }
  .footer .social-icon{
    position: relative; top: 70px;
    position: relative; left: auto;
    width: 70%;
    right: 28%;
  }
  .cpytex {
    position: relative; top: 55px;
    position: relative; left: auto;
    width: 200%;
    right: 40%;
  }
  .contact img {
    width: 150%;
  }
  .contact h2 {
    font-size: 55px;
    margin-bottom: 30px;
    width: 150%;
  }
}

@media screen and (min-width:768px) and (max-width:800px) {
  .banner h1 {
    min-height: 60px;
    font-size: 35px;
    width: 200%;
	}
  .banner p {
    color: #ffd700;
    font-style: TaHoma;
    font-size: 33px;
    letter-spacing: 0.8px;
    line-height: 1em;
    width: 130%;
  }
  .services p {
    color: #00ccff;
    font-size: 30px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
}
.services h3 {
  color: #ffd700;
  font-size: 30px;
}
  .row{
    display: flex;
    flex-direction: column;
    display: grid;
  }
  .proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 88.6vw;
  }
  .proj-imgbx2 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 88.6vw;
  }
  .proj-imgbx3 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 88.6vw;
  }
  .proj-txtx h4 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
  }
  .proj-txtx span {
    font-style: TaHoma;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 0.8px;
  }
  .new-email-bx {
    width: 480px;
    right: 40px;
    position: relative; top: 25px;
  }
  .new-email-bx button {
    padding: 20px 30px;
  }
  .newsletter-bx h3 {
    font-weight: 700;
    font-size: 38px;
    line-height: 27px;
    width: 200%;
    position: relative; right: 70px;
    margin: -5px 30px;
    text-align: justify;
  }
  .ms-auto{
    display: flex;
    flex-direction: row !important;
    position: relative; left: 50px;
    width: 100%;
  }
  .banner img{
    margin-left: 110%;
    margin-top: -200px;
  }
  .footer .social-icon{
    position: relative; top: 70px;
    position: relative; left: auto;
    width: 70%;
    right: 28%;
  }
  .cpytex {
    position: relative; top: 55px;
    position: relative; left: auto;
    width: 200%;
    right: 40%;
  }
  .contact img {
    width: 150%;
  }
  .contact h2 {
    font-size: 55px;
    margin-bottom: 30px;
    width: 150%;
  }
}

@media screen and (min-width:721px) and (max-width:767px) {
  body {
    zoom: 2.5;
    width: 200%;
  }
  .banner h1 {
    min-height: 60px;
    font-size: 25px;
	}
  .banner p {
    color: #ffd700;
    font-style: TaHoma;
    font-size: 23px;
    letter-spacing: 0.8px;
    line-height: 1em;
    width: 100%;
  }
  .row{
    display: flex;
    flex-direction: column;
    display: grid;
  }
  .proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 68.6vw;
  }
  .proj-imgbx2 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 68.6vw;
  }
  .proj-imgbx3 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 68.6vw;
  }
  .new-email-bx {
    width: 480px;
    right: 40px;
    position: relative; top: 25px;
  }
  .new-email-bx button {
    padding: 20px 30px;
  }
  .newsletter-bx h3 {
    font-weight: 700;
    font-size: 38px;
    line-height: 27px;
    position: relative; right: 70px;
    margin: -5px 30px;
    text-align: justify;
  }
  .ms-auto{
    display: flex;
    flex-direction: row !important;
    position: relative; left: 50px;
    width: 100%;
  }
  .footer .social-icon{
    position: relative; top: 70px;
    position: relative; left: auto;
    width: 70%;
    right: 13%;
  }
  .cpytex {
    position: relative; top: 55px;
    position: relative; left: auto;
    width: 200%;
    right: 40%;
  }
}

@media screen and (min-width:685px) and (max-width:720px) {
  body {
    zoom: 2.5;
    width: 200%;
  }
  .banner h1 {
    min-height: 60px;
    font-size: 25px;
	}
  .banner p {
    color: #ffd700;
    font-style: TaHoma;
    font-size: 23px;
    letter-spacing: 0.8px;
    line-height: 1em;
    width: 100%;
  }
  .row{
    display: flex;
    flex-direction: column;
    display: grid;
  }
  .proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 73.6vw;
  }
  .proj-imgbx2 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 73.6vw;
  }
  .proj-imgbx3 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 73.6vw;
  }
  .new-email-bx {
    width: 480px;
    right: 40px;
    position: relative; top: 25px;
  }
  .new-email-bx button {
    padding: 20px 30px;
  }
  .newsletter-bx h3 {
    font-weight: 700;
    font-size: 38px;
    line-height: 27px;
    position: relative; right: 70px;
    margin: -5px 30px;
    text-align: justify;
  }
  .ms-auto{
    display: flex;
    flex-direction: row !important;
    position: relative; left: 50px;
    width: 100%;
  }
  .footer .social-icon{
    position: relative; top: 70px;
    position: relative; left: auto;
    width: 70%;
    right: 13%;
  }
  .cpytex {
    position: relative; top: 55px;
    position: relative; left: auto;
    width: 200%;
    right: 40%;
  }
}

@media screen and (min-width:576px) and (max-width:684px) {
  body {
    zoom: 2.5;
    width: 200%;
  }
	 .banner h1 {
    min-height: 60px;
    font-size: 25px;
	}
  .banner p {
    color: #ffd700;
    font-style: TaHoma;
    font-size: 23px;
    letter-spacing: 0.8px;
    line-height: 1em;
    width: 100%;
  }
  .row{
    display: flex;
    flex-direction: column;
    display: grid;
  }
  .proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 75.6vw;
  }
  .proj-imgbx2 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 75.6vw;
  }
  .proj-imgbx3 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 75.6vw;
  }
  .new-email-bx {
    width: 420px;
    right: 40px;
    position: relative; top: 25px;
  }
  .new-email-bx button {
    padding: 20px 30px;
  }
  .newsletter-bx h3 {
    font-weight: 700;
    font-size: 38px;
    line-height: 27px;
    width: 100%;
    position: relative; right: 70px;
    margin: -5px 30px;
    text-align: justify;
  }
  .ms-auto{
    display: flex;
    flex-direction: row !important;
    position: relative; left: 50px;
    width: 100%;
  }
  .footer .social-icon{
    position: relative; top: 70px;
    position: relative; left: auto;
    width: 70%;
    right: 13%;
  }
  .cpytex {
    position: relative; top: 55px;
    position: relative; left: auto;
    width: 200%;
    right: 40%;
  }
}
/* sm */
@media screen and (min-width:476px) and (max-width: 575px) and (orientation:portrait) {
  body {
    zoom: 2;
    width: 200%;
  }
	.banner h1 {
    min-height: 80px;
    font-size: 35px;
	}
  nav.navbar a.navbar-brand {
    width: 80px;
  }
  .social-icon {
    display: inline-block;
    margin-left: 135px;
  }
  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-weight: 400;
    margin-left: 43px;
  }
  .new-email-bx {
    width: 420px;
    right: 32px;
    position: relative; top: 25px;
  }
  .new-email-bx button {
    padding: 20px 30px;
  }
  .newsletter-bx h3 {
    font-weight: 700;
    font-size: 38px;
    line-height: 27px;
    width: 100%;
    position: relative; right: 70px;
    margin: -5px 30px;
    text-align: justify;
  }
  .row{
    display: flex;
    flex-direction: column;
    display: grid;
  }
  .proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 95.6vw;
  }
  .proj-imgbx2 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 95.6vw;
  }
  .proj-imgbx3 {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 95.6vw;
  }
  .services p {
    color: #00ccff;
    font-size: 25px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
  }
  .services h3 {
  color: #ffd700;
  font-size: 25px;
  }
  .vai p {
    color: #ffd700;
    font-size: 25px;
  }
  .proj-txtx h4 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
  }
  .proj-txtx span {
    font-style: TaHoma;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.8px;
  }
  .ms-auto{
    display: flex;
    flex-direction: row !important;
    position: relative; left: auto;
  }
  .footer .social-icon{
    position: relative; top: 70px;
    position: relative; left: auto;
    width: 70%;
    right: 13%;
  }
  .cpytex {
    position: relative; top: 55px;
    position: relative; left: auto;
    width: 100%;
  }
}

@media (max-width:475px) {
  body {
    zoom: 0.8;
    width: 99.9%;
  }
	.banner h1 {
    min-height: 80px;
    font-size: 35px;
	}
  nav.navbar a.navbar-brand {
    width: 80px;
  }
  .social-icon {
    display: inline-block;
    margin-left: 135px;
  }
  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-weight: 400;
    margin-left: 28px;
  }
  .new-email-bx {
    width: 420px;
    right: 32px;
    position: relative; top: 25px;
  }
  .new-email-bx button {
    padding: 20px 30px;
  }
  .newsletter-bx h3 {
    font-weight: 700;
    font-size: 38px;
    line-height: 27px;
    width: 100%;
    position: relative; right: 70px;
    margin: -5px 30px;
    text-align: justify;
  }
  .services p {
    color: #00ccff;
    font-size: 25px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
  }
  .services h3 {
  color: #ffd700;
  font-size: 25px;
  }
  .vai p {
    color: #ffd700;
    font-size: 25px;
  }
  .proj-txtx h4 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
  }
  .proj-txtx span {
    font-style: TaHoma;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.8px;
  }
  .ms-auto{
    display: flex;
    flex-direction: row !important;
    position: relative; left: auto;
    width: 100%;
  }
  .price-slider .item img {
    width: 70%;
    margin: 5px 5px;
  }
  .price-slider {
    width: 140%;
    margin: 5px 5px;
    position: relative;
    right: 60px;
  }
  .projects .nav.nav-pills {
    width: 100%;
  }
  .projects p {
    width: 100%;
  }
  .footer .social-icon{
    position: relative; top: 70px;
    position: relative; left: auto;
    right: 65px;
  }
  .cpytex {
    position: relative; top: 55px;
    position: relative; left: auto;
  }
  .logo {
    width: auto;
    position: relative; top: 50px;
  }
}

@media (max-width:375px) {
  body {
    zoom: 0.7;
    width: 99.9%;
  }
}

@media (max-width:325px) {
  body {
    zoom: 0.6;
    width: 99.9%;
  }
}

@media (max-width:280px) {
  body {
    zoom: 0.5;
    width: 99.9%;
  }
}